import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons/faCloudDownloadAlt";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons/faCheckSquare";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faEraser } from "@fortawesome/free-solid-svg-icons/faEraser";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faMinusSquare } from "@fortawesome/free-regular-svg-icons/faMinusSquare";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons/faPlusSquare";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faSquare } from "@fortawesome/free-regular-svg-icons/faSquare";
import { faStepBackward } from "@fortawesome/free-solid-svg-icons/faStepBackward";
import { faStepForward } from "@fortawesome/free-solid-svg-icons/faStepForward";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize";

// Add all icons to the library so you can use it in your page
library.add(faAlignLeft);
library.add(faArrowDown);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faArrowUp);
library.add(faCalendarAlt);
library.add(faCaretDown);
library.add(faCaretRight);
library.add(faCaretUp);
library.add(faCheck);
library.add(faCheckSquare);
library.add(faCloudDownloadAlt);
library.add(faCopy);
library.add(faEdit);
library.add(faEraser);
library.add(faExclamationTriangle);
library.add(faExternalLinkAlt);
library.add(faFileExcel);
library.add(faEye);
library.add(faFileAlt);
library.add(faFolder);
library.add(faFolderOpen);
library.add(faKey);
library.add(faLink);
library.add(faMinus);
library.add(faMinusSquare);
library.add(faPencilAlt);
library.add(faPlus);
library.add(faPlusSquare);
library.add(faSave);
library.add(faSearch);
library.add(faSpinner);
library.add(faSquare);
library.add(faStepBackward);
library.add(faStepForward);
library.add(faTimes);
library.add(faTools);
library.add(faTrash);
library.add(faUser);
library.add(faUpload);
library.add(faWindowMinimize);
library.add(faBars);

dom.watch();
